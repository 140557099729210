import React from "react";
import { Grid, makeStyles, Avatar, Typography } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
// import pk from "../static/images/pk.jpg";
// import pj from "../static/images/pooja.jpg";
import juhi from "../static/images/juhi.jpg";
import durgesh from "../static/images/Durgesh.jpg";
import shivam from "../static/images/shivam.jpg";
import brijesh from "../static/images/brijesh.jpg";
import richa from "../static/images/richa.jpg";
import samiksha from "../static/images/samiksha.jpg";
import piyush from "../static/images/piyush.jpg";
import ganesh from "../static/images/ganesh.jpg";
import yogendra from "../static/images/yogendra.jpg";
import devendra from "../static/images/devendra.jpg";
import anurag from "../static/images/anurag.jpg";

const staffInfo = [
  // { img: pk, name: "Pankaj", position: "Student" },
  // { img: pj, name: "Pooja", position: "Student" },
  { img: brijesh, name: "Mr. Brijesh Pandey", position: "HOD (CS & IT)" },
  { img: shivam, name: "Mr. Shivam Shukla", position: "Assistant Professor" },
  { img: richa, name: "Dr. Richa Verma ", position: "Assistant Professor" },
  { img: durgesh, name: "Mr. Durgesh Tiwari", position: "Assistant Professor" },
  { img: juhi, name: "Ms. Juhi Singh", position: "Assistant Professor" },
  { img: samiksha, name: "Ms. Samiksha Singh", position: "Assistant Professor" },
  { img: devendra, name: "Mr. Devendra Agarwal", position: "Assistant Professor" },
  { img: anurag, name: "Mr. Anurag Jaiswal", position: "Assistant Professor" },
  { img: piyush, name: "Mr. Peeyush Pathak", position: "Assistant Professor" },
  { img: yogendra, name: "Mr. Yogendra Pratap", position: "Assistant Professor" },
  { img: ganesh, name: "Dr. Ganesh Chandra", position: "Assistant Professor" },
 
];

const useStyles = makeStyles((theme) => ({
  card: {
    width: "60vw",
    minHeight: "30vh",
   
    borderRadius: '10px',
    backgroundColor: "rgba(255,255,255,0.2)",
    [theme.breakpoints.up("md")]: {
      width: "15vw",
      height: "35vh",
    //  
    },
    boxShadow: '2px 2px 30px  #FFFFFF'
    // border: '2px solid '
    // border: "linear-gradient(45deg,#ffc312,#ee5a24,#00a8ff)",    
  },
 facultyText: {
    marginTop: "23%",
    marginBottom: "5%",
    border: '2px solid white',
    padding: '1%',
    borderRadius: '10px',
    textTransform: 'uppercase',
    [theme.breakpoints.up("md")]: {
        marginTop: "10%",
        marginBottom: "3%",
      },
      color: 'white'
  },
  avatar: {
    marginTop: "10%",
    width: theme.spacing(15),
    height: theme.spacing(15),
    border: "2px solid white",

  },
  nameFaculty: {
    color: "white",
   margin: '5%'
  },
  namePosition: {
    color: "white",
  },
}));

export default function StaffCarousel() {

  const classes = useStyles();
  return (
    <Grid container justify="center" direction='column' alignItems="center" className={classes.gridMain}>
    <Grid item className={classes.facultyText}>
    <Typography  variant='h5'>
                Faculty of CS & IT
              </Typography>
            </Grid>
      
      <Carousel number={2} className={classes.card}>
        {staffInfo.map((item) => (
          <Grid item container direction='column' alignItems="center" justify="center">
            <Avatar src={item.img} className={classes.avatar}></Avatar>

            <Grid item  className={classes.nameFaculty}>
              <Typography variant>
                {item.name}
              </Typography>
            </Grid>
            <Grid item className={classes.namePosition}>
              <Typography  variant>
                {item.position}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Carousel>
    </Grid>
  );
}
