
import './App.css';
import Covid from './components/covid'
import { CssBaseline, responsiveFontSizes, makeStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core'



let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

function App() {
  return (
  <MuiThemeProvider theme={theme}>
     <Covid/>
     <CssBaseline/>
    </MuiThemeProvider>
  );
}

export default App;
