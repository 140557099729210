import React, { useState } from "react";
import {
  Grid,
  Card,
  Paper,
  makeStyles,
  Typography,
  CardContent,
 
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  gridMain: {
   
   
  },
  upDataText:{
        color:'white',
        padding: '3%',
        textTransform: 'uppercase',
        marginTop: '4%',
        [theme.breakpoints.up('md')]:{
          marginTop: '1%',
        }
   
  },
  gridItem: {
    margin: theme.spacing(1),
    border: " 2px solid black",
    backgroundColor: "rgba(255,255,255,0.02)",
  },
  cardItem: {
    width: "100%",
    backgroundColor: "rgba(255,255,255,0.2)",
  },
  cardSpan: {
    fontSize: "1rem",
  },
  cardText: {
    fontSize: "1rem",
    fontWeight: 520,
    color: "white",
  },
  cardNumber: {
    fontSize: "1.5rem",
    color: "white",
    textShadow: "5px 6px 8px black",
    align: "center",
    fontWeight: 700,
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem",
      fontWeight: 720,
      padding: theme.spacing(1),
    },
  },

 
 
}));

export default function CardData(props) {
  const classes = useStyles();
 
  const { upData,timeDate } = props;
   


  return (
    <div  align="center">
     <Typography  className={classes.upDataText} variant='h5'>Utter Pradesh Covid-19 Data </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.gridMain}
      >
   

        <Grid
          item
          className={classes.gridItem}
          component={Paper}
          container
          xs={5}
          sm={3}
          md={3}
          lg={3}
        >
          <Card className={classes.cardItem}>
            <CardContent>
              <Typography className={classes.cardText}>
                <span className={classes.cardSpan}> 🟨 </span> Total 
              </Typography>
              <Typography className={classes.cardNumber} align="center">
                {upData.totalConfirmed}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          className={classes.gridItem}
          component={Paper}
          container
          xs={5}
          sm={3}
          md={3}
          lg={3}
        >
          <Card className={classes.cardItem}>
            <CardContent>
              <Typography className={classes.cardText}>
                <span className={classes.cardSpan}> 🟩 </span> Confirmed
              </Typography>
              <Typography className={classes.cardNumber} align="center">
               {upData.confirmedCasesIndian}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          className={classes.gridItem}
          component={Paper}
          container
          xs={5}
          sm={3}
          md={3}
          lg={3}
        >
          <Card className={classes.cardItem}>
            <CardContent>
              <Typography className={classes.cardText}>
                <span className={classes.cardSpan}> 🟦 </span> Recovered
              </Typography>
              <Typography className={classes.cardNumber} align="center">
                {upData.discharged}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          className={classes.gridItem}
          component={Paper}
          container
          xs={5}
          sm={3}
          md={3}
          lg={3}
        >
          <Card className={classes.cardItem}>
            <CardContent>
              <Typography className={classes.cardText}>
                <span className={classes.cardSpan}> 🟥 </span> Deaths
              </Typography>
              <Typography className={classes.cardNumber} align="center">
              {upData.deaths}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          className={classes.gridItem}
          component={Paper}
          container
          xs={5}
          sm={3}
          md={3}
          lg={3}
        >
          <Card className={classes.cardItem}>
            <CardContent>
              <Typography className={classes.cardText}>
                <span className={classes.cardSpan}> 🟧 </span> Updated 
              </Typography>
              <Typography className={classes.cardNumber} align="center">
                Yesterday
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
