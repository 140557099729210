import React from "react";
import {
  Grid,
  makeStyles,
  Avatar,
  Typography,
  Card,
  TextField,
  CardContent,
  Paper
} from "@material-ui/core";
import pk from "../static/images/pk.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "20vh",
    marginTop: "20%",
    // border: "2px solid white",
    backgroundColor: "rgba(255,255,255,0.2)",
    [theme.breakpoints.up("md")]: {
      marginTop: "10%",
    },
  },
  avatar: {
    height: "100px",
    width: "100px",
    position: "relative",
    top: "-53px",
    left: "0",
    border: "4px solid white",
    marginBottom: "-6%",
    [theme.breakpoints.up("md")]: {
      marginBottom: "-2%",
    },
  },
  footerTypo: {
    fontSize: '0.7em',
    color: "white",
    marginBottom: "4%",
    [theme.breakpoints.up("md")]: {
      fontSize: '1em',
    },
   
  },
  studentInfoSpan:{
      fontSize: '80%'
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.root}
      component={Paper}
    >
      <Avatar src={pk} className={classes.avatar}></Avatar>
      <Grid item>
        <Typography className={classes.footerTypo} variant="h5">
         
          Developed By ©️ 2021
        </Typography>
      </Grid>
      <Grid style={{paddingBottom:'10px'}}  item>
       
        <Typography className={classes.footerTypo} variant>
         
         Pankaj Kumar Yadav <span  className={classes.studentInfoSpan}><i> (A student of Goel Institute of Technology & Management) </i> </span>
        </Typography>
      </Grid>
    </Grid>
  );
}
