import React, { useEffect, useState } from 'react'
import CardData from './CardData'
import Header from './Header'
import Statewise from './Statewise'
import { makeStyles } from '@material-ui/core'
import covid from '../static/images/corona4.jpg'
import StaffCarousel from './StaffCarousel'
import Footer from './Footer'

const useStyles = makeStyles({
  root: {
    minHeight: '100%',
    minWidth: '100%',
    backgroundImage: `url(${covid})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  divOpacity: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    minHeight: '100%',
    minWidth: '100%',
  },
})

export default function Covid() {
  const [data, setData] = useState([])
  const [upData, setupData] = useState([])
  const [timeDate, setTimeDate] = useState([])
  const classes = useStyles()

  const getCovidData = async () => {
    try {
      const india = await fetch('https://api.rootnet.in/covid19-in/stats/latest');
      const actualData = await india.json()
      
      // console.log(actualData)

      setTimeDate(actualData)
      setData(actualData.data.regional)
      setupData(actualData.data.regional[34])
      // console.log(upData)
    } catch (err) {
      console.log(err)
    }
  }

  // console.log(data)

  useEffect(() => {
    getCovidData()
  }, [])



  return (
    <div className={classes.root}>
      <div className={classes.divOpacity}>
        <Header />
        <StaffCarousel />
        {timeDate &&<CardData upData={upData} timeDate={timeDate} />}
         {data &&<Statewise data={data} />} <Footer />
      </div>
    </div>
  )
}
