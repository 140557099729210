import React, {useState} from "react";
import { makeStyles } from "@material-ui/core";
import { Grid, Avatar, Paper, Typography, Button } from "@material-ui/core";
import goel_logo from "../static/images/goel_logo.webp";

const useStyle = makeStyles((theme) => ({
  
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    [theme.breakpoints.up('md')]:{
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
  headerText: {
    fontWeight: "600",
    fontSize: "0.9rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.7rem",
    },
  },
  gridItem: {
    //  border: '2px solid black',
    height: "100%",
    width: "100%",
  },
  lastUpdated: {
    // border: "2px solid white",
    height: "80%",
    width: "70%",
    position: "relative",
    top: "50px",
    right: "0",
    color: "black",
    backgroundColor: "#FFFF00",
    [theme.breakpoints.up("md")]: {
      width: "15vw",
      height: "20vh",
      position: "relative",
      top: "20px",
      right: "0",
    },
  },
  btnContact: {
    color: 'white',
     width: "7vw",
     fontSize: '0.5em',
     border: '2px solid',
    height: "5vh",
    background: " linear-gradient(to right, #cb356b, #bd3f32)",

    [theme.breakpoints.up("md")]: {
      width: "15vw",
      height: "8vh",
      fontSize: '1em',
      
    },
  }

}));

export default function Header() {
  const [bgColor,setbgColor] = useState(false);
  const classes = useStyle();

  const changeBackground= ()=>{
    if(window.scrollY >=50){
      setbgColor(true);

    }else{
      setbgColor(false);
    }
  }
 window.addEventListener('scroll', changeBackground)
  return (
    <Grid
      container
      
      className={bgColor ? 'root active' : 'root'}
      direction="row"
      alignItems="center"
      justify="space-around"
    >
      <Grid
        item
        container
        alignItems="center"
        justify="center"
        xs
        className={classes.gridItem}
      >
        <Avatar xs sm md  className={classes.avatar} alt="goel" src={goel_logo} />
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justify="center"
        xs={7}
        className={classes.gridItem}
      >
        <Typography align="center" className={classes.headerText}>
          <span>Goel</span> Institute Of Technology & Management
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justify="center"
        xs
      >
        <a href="https://wa.me/+917007682159" style={{ textDecoration: 'none'}} target="_blank"><Button variant="outlined" className={classes.btnContact} >
          Contact Me
        </Button></a>
      </Grid>
    </Grid>
  );
}
