import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  head: {
    background: " linear-gradient(to right, #cb356b, #bd3f32)",
    
  },
  headTableCell: {
    background: " transparent",
    color: 'white',
    fontSize: 500,
    fontWeight: 700,
  },
  tableRow: {
     
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  headingStatewise:{
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      color: 'white',
      paddingBottom: theme.spacing(.7),
      backgroundColor: "#98hch6",
      fontWeight: 800
  },
  tableContainer:{
    maxHeight: '50vh',
    paddingBottom: theme.spacing(3),
    
  }
}));

export default function Statewise(props) {
  const { data } = props;
  const classes = useStyles();

  return (
    <Container >
      <Typography
      className={classes.headingStatewise}
        backgroundColor="red"
        variant="h3"
        align="center"
        
      >
        Statewise Data
      </Typography>

      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table  >
          <TableHead >
            <TableRow  className={classes.head}>
              <TableCell className={classes.headTableCell}> <Typography>State  </Typography> </TableCell>
              <TableCell className={classes.headTableCell}> <Typography> Total Confirmed</Typography> </TableCell>
              <TableCell className={classes.headTableCell}>
              <Typography>   Confirmed </Typography>
             
              </TableCell>
              <TableCell className={classes.headTableCell}>
              <Typography>
              Recoverd
              </Typography>
             
              </TableCell>
              <TableCell className={classes.headTableCell}><Typography>Deaths </Typography>   </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow className={classes.tableRow}>
                <TableCell> <Typography>{item.loc}</Typography> </TableCell>
                <TableCell><Typography> {item.totalConfirmed} </Typography> </TableCell>
                <TableCell><Typography>{item.confirmedCasesIndian}</Typography>  </TableCell>
                <TableCell><Typography>{item.discharged} </Typography>  </TableCell>
                <TableCell> <Typography>{item.deaths}</Typography>  </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
